import React, { useEffect } from 'react'

import { useInView } from 'react-intersection-observer'

import ProductOverviewBedtimeGreens from './ProductOverviewBedtimeGreens'
import ProductOverviewKombiGreens from './ProductOverviewKombiGreens'
import ProductOverviewPowerGreens from './ProductOverviewPowerGreens'
import trackViewItemListEvent from '../../modules/common/lib/tracking/trackViewItemListEvent'
import { IProductsBySlug } from '../../modules/products/interfaces/productInterfaces'
import { bedtimeGreensSlug, powerGreensSlug, powerKombiGreensSlug } from '../../utils/slugs'

interface IProductOverview {
  productsBySlug: IProductsBySlug
}

const ProductOverview: React.FC<IProductOverview> = ({ productsBySlug }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    delay: 500
  })

  useEffect(() => {
    if (inView) {
      trackViewItemListEvent([
        productsBySlug[powerGreensSlug],
        productsBySlug[powerKombiGreensSlug],
        productsBySlug[bedtimeGreensSlug]
      ])
    }
  }, [inView])

  return (
    <section
      id="products"
      className="products bg-gray-300 px-4 py-16 lg:py-24 lg:pb-32 text-center">
      <div className="max-w-5xl w-full mx-auto">
        <h2 className="font-extrabold w-full text-center text-xl md:text-2xl xl:text-4xl text-pine-green-700 px-8 mb-8 md:mb-20 lg:mb-16 xl:mb-20 z-10">
          Unsere Greens
        </h2>

        <div
          className="gap-6 md:grid-flow-col space-y-6 md:space-y-0 md:auto-rows-fr items-center grid grid-cols-1 md:grid-cols-3 max-w-6xl mx-auto text-pine-green-700"
          ref={ref}>
          <ProductOverviewPowerGreens product={productsBySlug[powerGreensSlug]} />

          <ProductOverviewKombiGreens product={productsBySlug[powerKombiGreensSlug]} />

          <ProductOverviewBedtimeGreens product={productsBySlug[bedtimeGreensSlug]} />
        </div>
      </div>
    </section>
  )
}

export default ProductOverview
