import React from 'react'

import Image from 'next/image'
import Link from 'next/link'

import Button from './Button'
import { IProduct } from '../../modules/products/interfaces/productInterfaces'
import { toEuro } from '../../utils'
import ArrowToRightIcon from '../../icons/ArrowToRightIcon'

interface IProductOverviewBedtimeGreens {
  product: IProduct
}

const ProductOverviewBedtimeGreens: React.FC<IProductOverviewBedtimeGreens> = ({ product }) => (
  <Link href="/products/bedtime-greens">
    <a
      className="lg:h-full md:hover:opacity-80 transform md:hover:scale-105 transition-all"
      href="# ">
      <div className="lg:h-full bg-white rounded-lg shadow-lg p-4 pb-6 pt-0 lg:p-6 lg:pb-8 lg:pt-4 md:min-h-product-card flex flex-col justify-between flex-wrap items-center">
        <div className="relative">
          <Image
            alt="Power Greens"
            height={331}
            src="/images/product_bedtime_white.jpg"
            width={460}
          />

          <div className="absolute right-0 top-0 mt-4 lg:-mr-2 lg:mt-2 xl:-mr-10 xl:-mt-8">
            <img
              className="mx-auto md:mx-0 text-center w-20 md:w-20 xl:w-24"
              src="/images/badge-daily.png"
              width="100"
              height="100"
              alt="Nur 1 € pro Tag"
            />
          </div>

          <h3 className="mt-2 font-bold lg:text-lg h-12 flex items-center justify-center">
            Bedtime Greens
          </h3>

          <p className="text-tiny leading-snug">
            Auf natürliche Weise besser zur Ruhe kommen – für eine gesunde und erholsame Nachtruhe.
          </p>
        </div>

        <div>
          <div className="relative flex items-center justify-center text-xs text-orange-700 font-semibold">
            <span className="inline-block uppercase tracking-widest w-fit pt-6 md:rounded-full">
              Angebotspreis
              <br />
              (nur für kurze Zeit)
            </span>
          </div>

          <p className="text-orange-700 font-semibold text-lg py-4 leading-tight ">
            {product.price !== product.regularPrice && (
              <span className="line-through text-gray-400 pr-2 font-normal">
                {toEuro(parseFloat(product.regularPrice))}
              </span>
            )}
            {toEuro(parseFloat(product.price))}
            <br />
            <span className="text-xs text-center text-gray-400 font-normal">
              inkl. MwSt., versandkostenfrei
            </span>
          </p>

          <Button
            backgroundColor="mx-auto relative bg-pine-green-700 md:hover:bg-pine-green-500 md:hover:shadow-lg bottom-0"
            textColor="text-white">
            <div className="inline-block mr-4 -mt-0.5">
              <ArrowToRightIcon />
            </div>
            <span className="inline-block">Zu den Greens</span>
          </Button>
        </div>
      </div>
    </a>
  </Link>
)

export default ProductOverviewBedtimeGreens
