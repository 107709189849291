import trackEvent from './trackEvent'
import { IProduct } from '../../../products/interfaces/productInterfaces'

const trackViewItemListEvent = (products: IProduct[]): void => {
  trackEvent({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: 'product_overview',
      item_list_name: 'Product Overview',
      items: products
        .filter((product) => product) // TODO: should not be necessary -> caller should send only IProducts
        .map((product: IProduct) => ({
          item_brand: product.brand.name,
          item_id: product.sku,
          item_name: product.name,
          price: product.price,
          quantity: 1
        }))
    }
  })

  trackEvent({
    event: 'ua_view_item_list',
    ecommerce: {
      currencyCode: 'EUR',
      impressions: {
        products: products
          .filter((product) => product) // TODO: should not be necessary -> caller should send only IProducts
          .map((product: IProduct) => ({
            brand: product.brand.name,
            category: '',
            coupon: '',
            id: product.sku,
            name: product.name,
            price: product.price,
            quantity: 1,
            variant: ''
          }))
      }
    }
  })
}

export default trackViewItemListEvent
