import React from 'react'

import Image from 'next/image'
import Link from 'next/link'

import Button from './Button'
import { IProduct } from '../../modules/products/interfaces/productInterfaces'
import { toEuro } from '../../utils'
import ArrowToRightIcon from '../../icons/ArrowToRightIcon'

interface IProductOverviewKombiGreens {
  product: IProduct
}

const ProductOverviewKombiGreens: React.FC<IProductOverviewKombiGreens> = ({ product }) => (
  <Link href="/products/kombi-greens">
    <a className="md:hover:opacity-80 transform md:hover:scale-105 transition-all" href="# ">
      <div className="relative bg-white rounded-lg shadow-xl p-4 pb-6 pt-0 lg:p-6 lg:pb-12 lg:pt-6 -mt-6 -mb-6">
        <Image alt="Power Greens" height={331} src="/images/product_kombi_white.jpg" width={460} />
        <div className="absolute right-0 top-0 mt-4 md:mt-5 mr-4 xl:-mr-4 xl:-mt-4">
          <img
            className="mx-auto md:mx-0 text-center w-20 md:w-20 xl:w-24"
            src="/images/badge-savings.png"
            width="100"
            height="100"
            alt="5,95 € Ersparnis ggü. dem Einzelkauf"
          />
        </div>
        <h3 className="mt-4 md:-mt-2 mb-4 font-bold lg:text-lg h-12 flex items-center justify-center">
          Bedtime &amp; Power Greens Kombi
        </h3>

        <p className="text-tiny leading-snug">
          Ideal, wenn du tagsüber viele Aufgaben und Termine meistern musst und Abends deine
          Gedanken kreisen, so dass du nur schwer zur Ruhe kommst.
        </p>

        <div className="mt-6 mx-auto uppercase text-xs text-white font-bold bg-red-500 w-fit rounded-full tracking-widest px-4 py-1">
          Vorteilspreis
        </div>

        <p className="text-red-600 font-bold text-lg py-4 leading-tight ">
          {product.price !== product.regularPrice && (
            <span className="line-through text-gray-400 pr-2 font-normal">
              {toEuro(parseFloat(product.regularPrice))}
            </span>
          )}
          {toEuro(parseFloat(product.price))}
          <br />
          <span className="text-xs text-center text-gray-400 font-normal">
            inkl. MwSt., versandkostenfrei
          </span>
        </p>

        <Button
          backgroundColor="mx-auto bg-pine-green-700 md:hover:bg-pine-green-500 md:hover:shadow-lg "
          textColor="text-white">
          <div className="inline-block mr-4 -mt-0.5">
            <ArrowToRightIcon />
          </div>
          <span className="inline-block">Zu den Greens</span>
        </Button>
      </div>
    </a>
  </Link>
)

export default ProductOverviewKombiGreens
