export const allUrgrowSlugs = ['basecamp']
export const urgrowSlug = 'basecamp'

export const allTggSlugs = ['bedtime-greens', 'power-greens', 'power-greens']
export const bedtimeGreensSlug = 'bedtime-greens'
export const powerGreensSlug = 'power-greens'
export const powerKombiGreensSlug = 'power-greens'

export const allHydeepSlugs = [
  'eye-patches',
  'spot-patches',
  'anti-wrinkle-patches',
  'patches-bundle'
]
export const eyePatchesSlug = 'eye-patches'

export const antiWrinklePatchesVariantSlug = 'anti-wrinkle-patches'
export const spotPatchesSlug = 'spot-patches'
export const patchesBundleSlug = 'patches-bundle'

// NOTE: Urban Eden
export const microgreensHomegrowingSetSlug = 'microgreens-homegrowing-set'
export const microgreensSaatpadsMixSlug = 'microgreens-saatpads-8er-mix'
export const cleansingTeeSetSlug = 'cleansing-tee-set'
export const wellbeingTeeSetSlug = 'well-being-tee-set'

export const allUrbanEdenSlugs = [
  microgreensHomegrowingSetSlug,
  microgreensSaatpadsMixSlug,
  cleansingTeeSetSlug,
  wellbeingTeeSetSlug
]
